import type { CustomLinkRecord, PageLinkRecord } from "~/graphql/datocms/generated";
import type { LinkItem } from "~/models/links";

export function getLink(link: any, localePath: (path: string) => string, target?: string): LinkItem {
  if (!link) {
    return { id: "0", label: `Unknown link` };
  }

  if (link?._modelApiKey === "custom_link") {
    const customLink = link as CustomLinkRecord;

    return {
      id: customLink.id,
      label: customLink.text,
      href: customLink.optLinkUrl,
      target: target ?? getTarget(customLink.optLinkUrl || "/"),
    };
  }

  if (link?._modelApiKey === "page_link") {
    const pageLink = link as PageLinkRecord;

    return {
      id: pageLink.id,
      label: pageLink.text,
      href: getUrl(pageLink.link!.slug, localePath),
      target: target ?? getTarget(getUrl(pageLink.link!.slug, localePath)),
    };
  }

  return { id: "0", label: `Unknown link type ${link._modelApiKey}` };
}

export function getTarget(path: string) {
  try {
    if (path.startsWith("http")) {
      const pathUrl = new URL(path);
      const requestUrl = useRequestURL();

      if (pathUrl.host !== requestUrl.host) {
        return "_blank";
      }
    }

    return "_self";
  }
  catch {
    return "_blank";
  }
}

export function getUrl(path: string, localePath: (path: string) => string) {
  if (path.indexOf(":") > 0) {
    return path;
  }

  return localePath(`/${path}`);
}

export function createProductUrl(productId: number, slug: string | null | undefined) {
  let validSlug = slug?.trim() || "name";

  // remove all hyphens at the end of the slug
  validSlug = validSlug.replace(/-+$/, "");

  return `/product/${validSlug}--${productId}`;
}
